<template>
  <TheModal
      title="Add tags to folder"
      @close="closeModal"
  >
        <div class="filters">
          <div class="filter">
    <span class="filter__label-dropdown">
      System
    </span>
            <Multiselect multiple :value="system" :options="systemsOptions" @input="changeInput('system', $event)"/>
          </div>
          <div class="filter">
    <span class="filter__label-dropdown">
      Room
    </span>
            <Multiselect multiple :value="room" :options="roomsOptions" @input="changeInput('room', $event)"/>
          </div>
          <div class="filter">
    <span class="filter__label-dropdown">
      Collection
    </span>
            <Multiselect multiple :value="collection" :options="collectionsOptions" @input="changeInput('collection', $event)"/>
          </div>
          <div class="filter">
    <span class="filter__label-dropdown">
      Material
    </span>
            <Multiselect multiple :value="material" :options="materialsOptions" @input="changeInput('material', $event)"/>
          </div>
        </div>
  </TheModal>
</template>

<script>
import {api} from "@/api";
import {Library} from "@/mixins/mixins";
import Multiselect from '@/components/drop-down-multi/Multiselect'
import TheModal from '@/components/modal/TheModal';

export default {
  name: 'AddFilters',
  components: {TheModal, Multiselect},
  props: {
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    folderName: {
      type: String,
      required: false,
      default: ''
    },
    closeModal: {
      type: Function
    },
  },
  data() {
    return {
      system: [],
      room: [],
      collection: [],
      material: [],
      systemsOptions: [],
      roomsOptions: [],
      materialsOptions: [],
      collectionsOptions: [],
    }
  },
  mixins: [Library],
  methods: {
    changeInput(key, value) {
      this[key] = value;

      const saveTag = (type, tag) => {
        api
            .get(`/api/s3/object/tag/add?key=${this.prefix}&type=${type}&tag=${tag}`)
            .then(() => {
            })
            .catch((err) => {
              console.log(
                  'The API is facing issues. Please try again later.n' + err,
              );
            });
      }

      if (this.room !== '-')
        saveTag(2, this.room);
      if (this.collection !== '-')
        saveTag(3, this.collection);
      if (this.material !== '-')
        saveTag(4, this.material);
      // this.fetchAPIData();
    },
    createCollectionsOptions(val) {
      const result = [];
      val.map(el => {
        return result.push(el.Tag)
      })
      this.collectionsOptions = result;
    },
    createMaterialsOptions(val) {
      const result = [];
      val.map(el => {
        return result.push(el.Tag)
      })
      this.materialsOptions = result;
    },
    createRoomsOptions(val) {
      const result = [];
      val.map(el => {
        return result.push(el.Tag)
      })
      this.roomsOptions = result;
    },
    createSystemsOptions(val) {
      const result = [];
      val.map(el => {
        return result.push(el.Tag)
      })
      this.systemsOptions = result;
    },

/*    sendTag() {
      api
          .post(`/api/s3/object/tag`, JSON.stringify({
            tag: '',
            key: `${this.prefix}${this.folderName}/`,
            type: ''
          }))
          .then(() => {
          })
          .catch((err) => {
            console.log(
                'The API is facing issues. Please try again later.n' + err,
            );
          });
    },*/
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'))
    api
        .get(`/api/s3/tag`)
        .then((response) => {
          this.createCollectionsOptions(response.collection);
          this.createMaterialsOptions(response.material);
          this.createRoomsOptions(response.room);
          this.createSystemsOptions(response.system);
        })
        .catch((err) => {
          console.log(
              'The API is facing issues. Please try again later.n' + err,
          );
        });

  },
  computed: {
  },
}
</script>


<style src="../style.scss" lang="scss">

</style>

<style scoped lang="scss">
.drop-down-select {
  width: 140px;
  padding: 5px 0;
  border: 1px solid #AFAFAF;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #DABE96;
  }
}

</style>