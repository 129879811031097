<template>
  <div>
    <a @click="downloadFile" class="download" :style="{'opacity': opacity}">
      <img src="../../assets/download.svg" alt="">
      <p>DOWNLOAD FILE</p>
    </a>
  </div>
</template>

<script>
import {api} from "@/api";
import download from "downloadjs";
import {mapState} from "vuex";
import {Library} from "@/mixins/mixins";

export default {
  name: 'Download',
  mixins: [Library],
  props: {
    opacity: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState(['files', 'library']),
  },

  mounted() {
    api.setBearer(localStorage.getItem('token'));
  },

  methods: {
    downloadFile() {
      if (this.files.path) {
        this.$store.commit('library/loading', true);

        api
            .download(`/api/s3/file?filename=${ this.files.path }`)
            .then((response) => {
                  const reader = response.body.getReader();
              let contentBuffer = new Int8Array();
              // response.body is a readableStream

              //function to retreive the next chunk from the stream
              const handleChunk = ({
                done,
                value,
              }) => {
                if(done) {
                  download(contentBuffer, this.files.name);
                  this.$store.commit('library/loading', false);

                  return;
                }
                // concat already loaded data with the loaded chunk
                contentBuffer = Int8Array.from([ ...contentBuffer, ...value ]);

                // retreive next chunk
                reader.read().then(handleChunk);
              };

              //retreive first chunk
              reader.read().then(handleChunk);
            })
            .catch((err) => {
              console.log(
                  'The API is facing issues. Please try again later.n' + err,
              );
              this.$store.commit('library/loading', false);

            });
      }
    }
  },
}
</script>

<style src="./operations.scss" lang="scss">
</style>