<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {AUTH_URL} from '@/config';

export default {
  name: 'App',
  computed: {
    ...mapState([
      'auth',
    ]),
  },
  created() {
     const urlParams = new URLSearchParams(window.location.search);
     this.AUTH_URL = AUTH_URL;
     if (urlParams.has('token') && !this.auth.token) {
      this.$store.commit('auth/sentToken', urlParams.get('token'));
    }
     if (!this.auth.token) {
      window.location.href = `${this.AUTH_URL}`;
    }
  },
}

</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #FFFFFF;
}

.multiselect__tag {
  background: none;
}

.button-submit {
  background: #DABE96;
  height: 40px;
  width: 150px;
  border: 0;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;

  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #FFFFFF;
}

</style>
