import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    id: null,
    token: null,
    role: 'manager',
    error: false,
    isLoading: false,
  },
  getters: {
    isAuth: ({ token }) => !!token,
  },
  mutations: {
    login(state, userData) {
      state.token = userData.token;
      state.id = userData.id;
      state.role = userData.role;
      state.error = false;
      localStorage.setItem('token', userData.token);
    },
    logout(state) {
      state.token = null;
      state.id = null;
      state.role = '';
      state.error = false;
      localStorage.removeItem('token');
    },
    error(state) {
      state.error = true;
    },
    sentToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    loading(state, bool) {
      state.isLoading = bool;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    login({ commit }, authData) {
      commit('loading', true);
      api.post('/api/auth/login', JSON.stringify({
        username: authData.username,
        password: authData.password,
      }))
        .then((res) => {
          if (res.statusCode === 401) {
            commit('error');
          }
          commit('login', {
            token: res.access_token,
            id: res.userId,
            role: res.role,
          });
          commit('loading', false);
        })
        .catch(() => {
          commit('error');
          commit('loading', false);
        });
    },
    logout({ commit }) {
      commit('logout');
    },
  },
};
