<template>
  <div v-if="isAuth" class="content">
    <faq-view v-if="faqOpen"></faq-view>
    <TopBar :prefix="prefix"/>
    <Permissions
        :selectedPrefix="selectedPrefix"
        :prefix="prefix"
        :multiSelectedPrefix="multiSelectedPrefix"
        @close="closeModal"
        v-if="modalLinks"
        @new-user="newUser"
    />
    <NewUser :selectedPrefix="selectedPrefix"
             :multiSelectedPrefix="multiSelectedPrefix"
             :prefix="prefix"
             :closeModal="closeModal"
             v-if="modalUser"/>
    <div class="s3browser"
         :class="{'homePageBrowser': isHome}"
    >
      <div class="operations">
        <div class="operations__back">
          <div class="back" v-show="!isHome">
            <img @click="goBack" src="../../assets/back.svg" alt="back">
          </div>
        </div>
        <div class="operations__bread-crumbs">
          <h4 class="bread-crumbs">{{ getBreadCrumbs() }}</h4>
        </div>
        <div class="operations__block">
          <Delete
              :closeModal="detailClose"
              :folderFullPath="fullPath"
              :opacity="detailImage || selected !== null || folderName ? 1 : 0.5"/>
          <EditFolder
              :folderName="folderName"
              :folderFullPath="fullPath"
              :selectedType="selectedType"
              :multiSelectedFiles="multiSelectedFilePrefix"
              v-if="editFolder"
              :closeModal="closeModal"/>
          <EditFolderIcon @open="editFolderModal" :opacity="!detailImage && folderName ? 1 : 0.5" :prefix="prefix"/>
          <Link @open-link="openModal" :opacity="!detailImage && multiSelectedPrefix[0] ? 1 : 0.5"/>
          <AddFilters
              :prefix="prefix"
              v-if="modalFilters"
              :closeModal="closeModal"
          />
          <AddFolder v-if="addFolderForm && !uploadForm && !modalFilters"
                     :closeModal="closeModal"
                     @add-filters="addFiltersHandler"
                     :prefix="prefix"/>
          <AddFolderIcon :opacity="!detailImage ? 1 : 0.5" @create="showAddFolderForm" :prefix="prefix"/>
          <LoadingButton :disabled="detailImage" @load-file="showUploadForm" :prefixProp="prefix"/>
          <Download :opacity="detailImage || selected !== null ? 1 : 0.5"/>
        </div>
      </div>
      <div v-show="!detailImage" id="s3content" class="vld-parent">
        <loading
            :active.sync="library.isLoading"
            :can-cancel="false"
            :is-full-page="true"
        ></loading>
        <h2 v-if="isHome && this.folders.items.length > 0" class="category-name">Projects</h2>
        <div :class="{'home-folders': isHome}" class="folders">
          <div
              v-for="(item, folderindex) in this.folders.items"
              :key="'folder' + folderindex"
              :class="{'home': isHome, 'noHome': !isHome}"
              class="folder"
          >
            <div
                @click="selectedFolder(item, folderindex)"
                @dblclick="openFolder(item)"
                class="folder-container"
                :class="{'errorImageFolder': errorImage[folderindex]}">
              <div class="folder__selected" v-if="multiSelected[folderindex]" @dblclick="openFolder(item)">
                <img src="../../assets/selected.svg" alt="checkbox">
              </div>
              <div class="folder__icon">
                <img src="../../assets/folder_icon.svg" alt="folder-icon">
              </div>
              <img
                  :src="getUrl(item.thumb)"
                  alt="folder"
                  class="preview"
                  :class="{'errorImage': errorImage[folderindex]}"
                  @error="errorHandler(folderindex, $event)"/>
            </div>
            <div
                :class="{'errorTitle': errorImage[folderindex]}"
                :style="multiSelected[folderindex] ? 'color: #DABE96' : 'color: black'"
                class='fcentered'>
              {{ title(item.Prefix) }}
            </div>
          </div>
        </div>
        <br/>
        <h2 v-if="isHome && this.groups.items.length > 0" class="category-name">Gallery</h2>
        <div v-if="isHome" :class="{'home-folders': isHome}" class="folders">
          <div
              v-for="(item, groupindex) in this.groups.items"
              :key="'group' + groupindex"
              :class="{'home': isHome, 'noHome': !isHome}"
              class="folder"
          >
            <div
                @click="selectedFolder(item, 'gallery_' + groupindex)"
                @dblclick="openFolder(item)"
                class="folder-container"
                :class="{'errorImageFolder': errorImage['gallery_' + groupindex]}">
              <div class="folder__selected" v-if="multiSelected['gallery_' + groupindex]" @dblclick="openFolder(item)">
                <img src="../../assets/selected.svg" alt="checkbox">
              </div>
              <div class="folder__icon">
                <img src="../../assets/folder_icon.svg" alt="folder-icon">
              </div>
              <img
                  :src="getUrl(item.thumb)"
                  alt="group"
                  class="preview"
                  :class="{'errorImage': errorImage['gallery_' + groupindex]}"
                  @error="errorHandler('gallery_' + groupindex, $event)"/>
            </div>
            <div
                :class="{'errorTitle': errorImage['gallery_' + groupindex]}"
                :style="multiSelected['gallery_' + groupindex] ? 'color: #DABE96' : 'color: black'"
                class='fcentered'>
              {{ item.Title }}
            </div>
          </div>
        </div>
        <br/>
        <div class="previews">
          <div
              v-for="(item2, fileindex) in this.files.items"
              :key="'file' + fileindex"
              class="file"
              :class="{'home': isHome, 'noHome': !isHome}"
          >
            <div
                @click="selectedItem(item2, fileindex)"
                @dblclick="detailItem(item2, fileindex)"
            >
              <div class="file-container">
                <div
                    class="file__selected"
                    v-if="multiSelectedFile[fileindex]"
                    @dblclick="detailItem(item2, fileindex)"
                >
                  <img src="../../assets/selected.svg" alt="checkbox">
                </div>
                <img
                  class="preview-file"
                  :src="getThumb(item2)"
                  alt="thumb"
                  @error="errorHandlerFile(fileindex, $event)"/>
              </div>
              <div
                  class="file-title"
                  :style="multiSelectedFile[fileindex] ? 'color: #DABE96' : 'color: black'">
                {{ itemfilename(item2.Key) }}
              </div>
              <div class="file-under__tag-container">
                <div v-for="(item2Tag, item2TagIndex) in item2.tags"
                  :key="'item2Tag ' + item2TagIndex"
                  v-show="item2Tag"
                  class="file-under__tag-name"
                >
                  {{ getItemFileTag(item2Tag) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Detail v-show="detailImage"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import {api} from '@/api';
import AddFolder from '@/components/operations/folder/add/AddFolder.vue';
import {mapGetters, mapState} from 'vuex';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import Footer from '@/components/layout/Footer';
import AddFolderIcon from '@/components/operations/folder/add/AddFolderIcon';
import Delete from '@/components/operations/Delete';
import Download from '@/components/operations/Download';
import LoadingButton from '@/components/operations/LoadingButton';
import Link from '@/components/operations/Link';
import {Library} from '@/mixins/mixins';
import Detail from '@/components/library/detail/Detail';
import TopBar from '@/components/layout/TopBar';
import Permissions from '@/components/permissions/Permissions';
import NewUser from '@/components/users/NewUser';
import EditFolderIcon from '@/components/operations/folder/edit/EditFolderIcon';
import EditFolder from '@/components/operations/folder/edit/EditFolder';
import {uniq} from "lodash-es";
import AddFilters from "@/components/filters/add/AddFilters";
import videoPreview from '../../assets/video_preview.png';
import FaqView from '@/components/faq/FaqView.vue';

export default {
  name: 'Library',
  components: {
    AddFilters,
    EditFolder,
    EditFolderIcon,
    NewUser,
    Permissions,
    TopBar,
    Detail,
    Link,
    Download,
    LoadingButton,
    Delete,
    AddFolderIcon,
    Footer,
    AddFolder,
    Loading,
    FaqView
  },
  mixins: [Library],
  computed: {
    ...mapGetters('auth', ['isAuth']),
    ...mapState(['auth', 'files', 'folders', 'library', 'groups', 'prefixSelected']),
    ...mapState({
      faqOpen: (state) => state.faq.faqOpen
    })
  },
  mounted() {
    this.$store.dispatch('appCtx/openLibrary', {});
    api.setBearer(localStorage.getItem('token'));

    // console.log('this.folders', this.folders);
    // console.log('this.files', this.files);
    // console.log('this.groups', this.groups, this.groups.items);
    // console.log('this.library', this.library);
    // console.log('this.prefixSelected', this.prefixSelected, this.prefixSelected.prefix);
    // console.log('Prefix', this.selectedPrefix);

    window.setTimeout(this.prepareStartFolder, 1000);

    this.fetchAPIData();
  },
  data() {
    return {
      currentFolder: ''
    };
  },
  methods: {
    getBreadCrumbs() {
      const filteredBreadCrumbs = this.breadCrumbs.substring(0, this.breadCrumbs.length - 1);
      return filteredBreadCrumbs;
    },
    title(path) {
      let t = '';
      if (path) {
        let parts = path.split('/');
        t = parts.length > 1 ? parts[parts.length - 2] : '';
        if (t.length > 50) {
          return `${t.substring(0, 50)}...`;
        }
      }
      return t;
    },
    getUrl(thumb) {
      return thumb ? api.getBaseUrl() + '/api/s3/cache/' + thumb : '';
    },
    prepareStartFolder() {
      // console.log('prepareStartFolder');
      if (this.prefixSelected.prefix) {
        this.prefix = this.prefixSelected.prefix;
        this.currentFolder = this.prefixSelected.prefix;
        this.fullPath = this.prefixSelected.prefix;
        this.breadCrumbs = this.fullPath;
        this.$store.dispatch('filters/setPrefix', this.prefixSelected.prefix);
        this.resetData();
      }
    },
    selectedItem(item, index) {
      console.log('selectedItem', item, index);
      this.selected = index;
      this.folderName = this.title(item.Key);
      this.fullPath = item.Key;
      this.selectedType = 'file';

      // сброс фолдера
      this.multiSelected = {};   
      // console.log('fileItem: ', this.files.items);
      // console.log('folderNameS: ', this.folderName);
      // console.log('fileFullPath : ', this.fullPath);
      // console.log('itemPrefix : ', item.Prefix);

      if (!this.multiSelectedFile) {
        this.multiSelectedFilePrefix = [];
      }

      /* if (this.multiSelectedFile[index]) {
        this.multiSelectedFile = { [index]: false};
      } else {
        this.multiSelectedFile = { [index]: true};
      } */

      // multiselect file
      if (this.multiSelectedFile[index]) {
        this.multiSelectedFile = {...this.multiSelectedFile, [index]: false};
      } else {
        this.multiSelectedFile = {...this.multiSelectedFile, [index]: true};
      }
            if (!this.multiSelectedFile[index]) {
        // this.multiSelected = {...this.multiSelected, [index]: false};
        this.multiSelectedFilePrefix = this.multiSelectedFilePrefix.filter((i, idx) => {
          if (index === idx) {
            return false;
          }
          return true;
        });
        delete this.multiSelectedFile[index];
      } else {
        this.multiSelectedFilePrefix.push(item.Key);
        this.multiSelectedFilePrefix = uniq(this.multiSelectedFilePrefix);
      }     

      // console.log('multiSelectedFile: ', this.multiSelectedFile);
      // console.log('multiSelectedFilePrefix', this.multiSelectedFilePrefix);

      //this.fullPath = this.multiSelectedFilePrefix;

      this.$store.dispatch('files/getInfo', {
        fileIndex: index,
        name: this.itemfilename(item.Key),
        thumb: api.getBaseUrl() + '/api/s3/cache/' + item.thumb,
        path: item.Key,
        orig: api.getBaseUrl() + '/api/s3/cache/' + item.cached + '.jpg',
      });
    },
    selectedFolder(item, index) {
      console.log('selectedFolder', item, index);
      this.multiSelectedFile = {};

      this.selectedPrefix = item.Prefix;
      this.folderName = this.title(item.Prefix);
      // console.log('itemPrefix: ', item.Prefix);
      // console.log('folderName: ', this.folderName);
      this.fullPath = item.Prefix;
      this.prefix = item.Prefix;
      this.selectedType = 'folder';

      this.$store.commit('folders/setFolderIndex', index);

      if (!this.multiSelected) {
        this.multiSelectedPrefix = [];
      }

      if (this.multiSelected[index]) {
        this.multiSelected = {...this.multiSelected, [index]: false};
      } else {
        this.multiSelected = {...this.multiSelected, [index]: true};
      }

      if (!this.multiSelected[index]) {
        // this.multiSelected = {...this.multiSelected, [index]: false};
        this.multiSelectedPrefix = this.multiSelectedPrefix.filter((i, idx) => {
          if (index === idx) {
            return false;
          }
          return true;
        });
        delete this.multiSelected[index];
      } else {
        this.multiSelectedPrefix.push(item.Prefix);
        this.multiSelectedPrefix = uniq(this.multiSelectedPrefix);
      }
    },
    errorHandler(index, e) {
      const images = require.context('../../assets/', false)
      e.target.src = images('./empty-folder.svg')
      this.errorImage = {...this.errorImage, [index]: true};
    },
    errorHandlerFile(index, e) {
      const images = require.context('../../assets/', false)
      e.target.src = images('./no_preview_2.png')
      this.errorImage = {...this.errorImage, [index]: true};
    },
    openFolder(item) {
      // console.log('openFolder', item.Prefix);
      this.prefix = item.Prefix;
      this.currentFolder = item.Prefix;
      this.fullPath = item.Prefix;
      console.log('currentPath: ', this.fullPath);
      this.breadCrumbs = this.fullPath;
      this.$store.dispatch('filters/setPrefix', item.Prefix);
      this.resetData();
      this.fetchAPIData();
    },
    detailItem(value, index) {
      this.$store.dispatch('files/getInfo', {
        fileIndex: index,
        thumb: api.getBaseUrl() + '/api/s3/cache/' + value.thumb,
        name: this.itemfilename(value.Key),
        path: value.Key,
        size: (value.Size / 1024).toFixed(),
        modified: new Date(value.LastModified).toLocaleString(),
        orig: api.getBaseUrl() + '/api/s3/cache/' + value.cached + '.jpg',
      });
      this.detailImage = true;
    },
    itemfilename(path) {
      return path.replace(this.currentFolder, '');
    },
    getItemFileTag(tag) {
      let tagName = '';
      // tags.forEach((tag) => {
      //   if (tag.type === 1) {
      //     systemTags += ' ' + tag.Tag;
      //   }

      //   return;
      // })
      // if (tag.type == 1) {
      //   tagName = tag.Tag;
      // }
      tagName = tag.Tag;
      return tagName;
    },
    addFiltersHandler() {
      this.addFolderForm = false;
      this.modalFilters = true;
    },
    newUser() {
      this.modalLinks = false;
      this.modalUser = true;
    },
    editFolderModal() {
      console.log('folderName: ', this.folderName);
      if (this.folderName)
        this.editFolder = true;
    },
    closeModal() {
      //this.fetchAPIData();
      this.modalLinks = false;
      this.addFolderForm = false;
      this.modalUser = false;
      this.modalFilters = false;

      this.resetData();
    },
    detailClose() {
      this.detailImage = false;
      this.resetData();
    },
    openModal() {
      if (!this.detailImage) {
        this.modalLinks = true;
      }
    },
    showUploadForm() {
      this.uploadForm = true;
      this.addFolderForm = false;
    },
    showAddFolderForm() {
      if (!this.detailImage) {
        this.addFolderForm = true;
        this.uploadForm = false;
      }
    },
    getThumb(item) {
      if (item.Key.includes('mp4')) return videoPreview;
      return api.getBaseUrl() + '/api/s3/cache/' + item.thumb;
    },
    goBack() {
      if (this.detailImage) {
        this.detailImage = false;
        return;
      }
      this.nextPage = false;
      // console.log(`goBack ${this.prefix}`);
      const arPrefix = this.currentFolder.split('/');
      // console.log(arPrefix);
      let newprefix = '';
      if (arPrefix.length > 1) {
        for (let i = 0; i < arPrefix.length - 2; i = i + 1) {
          newprefix += arPrefix[i] + '/';
        }
      }
      if (newprefix) {
        this.prefix = newprefix;
        this.currentFolder = newprefix;
      } else {
        // window.history.back();
        this.prefix = '';
        this.currentFolder = '';
      }

      if (this.folders.multipleFolders) {
        this.prefix = '';
        newprefix = '';
      }
      // console.log('newPrefix: ', newprefix);
      this.breadCrumbs = newprefix;
      this.$store.dispatch('filters/setPrefix', newprefix);
      this.resetData();
      this.fetchAPIData();
    },
  },
};
</script>

<style src="./style.scss" lang="scss">
</style>