<template>
  <div class="filters">
    <div class="filter">
    <span class="filter__label-dropdown">
      System
    </span>
      <Multiselect multiple :value="filters.system" :options="systemsOptions" @input="changeInput('system', $event)"/>
    </div>
    <div class="filter">
    <span class="filter__label-dropdown">
      Room
    </span>
      <Multiselect multiple :disabled="checkFiltersSystem" :value="filters.room" :options="roomsOptions"
                   @input="changeInput('room', $event)"/>
    </div>
    <div class="filter">
    <span class="filter__label-dropdown">
      Elements
    </span>
      <Multiselect multiple :disabled="checkFiltersSystem" :value="filters.collection" :options="collectionsOptions"
                   @input="changeInput('collection', $event)"/>
    </div>
    <div class="filter">
    <span class="filter__label-dropdown">
      Material
    </span>
      <Multiselect multiple :disabled="checkFiltersSystem" :value="filters.material" :options="materialsOptions"
                   @input="changeInput('material', $event)"/>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {api} from '@/api';
import {Library} from '@/mixins/mixins';
import Multiselect from '@/components/drop-down-multi/Multiselect';

export default {
  name: 'NavigateFilters',
  components: {Multiselect},
  props: {
    folderPath: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      systemsOptions: [],
      roomsOptions: [],
      materialsOptions: [],
      collectionsOptions: [],
    };
  },
  mixins: [Library],
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.$store.dispatch('filters/setCurrentProperty', { path: key, value });
      this.resetData();
      this.fetchAPIData();
    },
    // createCollectionsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.collectionsOptions = result;
    // },
    // createMaterialsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.materialsOptions = result;
    // },
    // createRoomsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.roomsOptions = result;
    // },
    // createSystemsOptions(val) {
    //   const result = [];
    //   val.map(el => {
    //     return result.push(el.Tag)
    //   })
    //   this.systemsOptions = result;
    // },
  },
  mounted() {
    api.setBearer(localStorage.getItem('token'))
    api
        .get(`/api/s3/tag`)
        .then((response) => {
          console.log({response});
          // this.createCollectionsOptions(response.collection);
          // this.createMaterialsOptions(response.material);
          // this.createRoomsOptions(response.room);
          // this.createSystemsOptions(response.system);
          this.materialsOptions = response.material;
          this.collectionsOptions = response.collection;
          this.roomsOptions = response.room;
          this.systemsOptions = response.system;
        })
        .catch((err) => {
          console.log(
              'The API is facing issues. Please try again later.n' + err,
          );
        });

  },
  watch: {
    checkFiltersSystem() {
      // this.resetData();
      this.fetchAPIData();
    },
  },
  computed: {
    ...mapState([
      'filters',
      'folders',
    ]),
    checkFiltersSystem() {
      if (!this.filters.system.length && this.filters.prefix === '') {
        this.$store.commit('filters/clearFilters');
      }
      return !this.filters.system.length && this.filters.prefix === '';
    },
  },
};
</script>


<style src="../style.scss" lang="scss">

</style>

<style scoped lang="scss">
.drop-down-select {
  width: 140px;
  padding: 5px 0;
  border: 1px solid #AFAFAF;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #DABE96;
  }
}

</style>