import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { isProd } from './config'
import { injectChat } from './utils/bitrix24chat'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

if (isProd) {
  injectChat(
    window,
    document,
    'https://cdn-ru.bitrix24.ru/b21928098/crm/site_button/loader_1_ovvkbs.js'
  );
}