<template>
  <TheModal
      title="Access to folder"
      @close="closeLink"
  >
    <div class="permission">
      <div class="permission__body">
        <div class="button-container">
          <button class="button" @click="newUser">+ Add a user</button>
          <button class="button" @click="closeLink">Done</button>
        </div>
        <div class="permission__user">
          <table>
            <tr v-for="(item, index) in users" :key="index">
              <td>
                <div class="permission__input-container">
                <span class="permission__label">
                  Name
                </span>
                  {{ fullname(item) }}
                </div>
              </td>
              <td v-show="!editPermission[item.id]" @click="editPermissionHandler(item.id)">
                <div class="permission__input-container">
                <span class="permission__label">
                  Access
                </span>
                  {{ access[item.id] }}
                </div>
              </td>
              <td v-show="editPermission[item.id]">
                <div class="permission__input-container-dropdown">
                <span class="permission__label">
                  Editing
                </span>
                  <drop-down-select
                      refCustom="user"
                      :value="permission[item.id]"
                      :options="options.permission"
                      @change="changePermission($event)"
                  >
                    <template v-slot:default="props">
                      <div>{{ props.option.name }}</div>
                    </template>
                  </drop-down-select>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </TheModal>
</template>

<script>
import { api } from '@/api';
import DropDownSelect from '@/components/drop-down/DropDownSelect';
import permission from '@/dictionary/permission';
import {FiltersMixin} from '@/mixins/mixins';
import TheModal from '@/components/modal/TheModal';

export default {
  name: 'Permissions',
  components: {TheModal, DropDownSelect },
  props: {
    prefix: String,
    selectedPrefix: {
      type: String,
      default: null,
      required: false,
    },
    multiSelectedPrefix: Array,
  },
  data() {
    return {
      users: [],
      userId: 0,
      access: {},
      permission: {},
      editPermission: {},
      permissionId: null,
    };
  },
  methods: {
    newUser() {
      this.$emit('new-user');
    },
    editPermissionHandler(id) {
      this.editPermission = {
        ...this.editPermission,
        [id]: true,
      };
      this.permissionId = id;

    },
    changePermission(value) {
      this.permission = {
        ...this.permission,
        [this.permissionId]: value,
      };
      if(value) {
        this.setAccess();
      }
    },
    getAccess(id) {
      api
          .get(`/api/s3/access?uid=${ id }&key=${ this.selectedPrefix || this.prefix }`)
          .then((response) => {
            if(response.access) {
              switch(response.access) {
                case 'read':
                  this.access = {
                    ...this.access,
                    [id]: 'Read',
                  };
                  break;
                case 'write':
                  this.access = {
                    ...this.access,
                    [id]: 'Write',
                  };
                  break;
                default:
                  this.access = {
                    ...this.access,
                    [id]: 'Access denied',
                  };
                  break;
              }
            } else {
              this.access = {
                ...this.access,
                [id]: 'Access denied',
              };
            }
            this.permission[id] = this.access[id];
          })
          .catch((err) => {
            console.log(
                'The API is facing issues. Please try again later.n' + err,
            );
          });
    },
    closeLink() {
      this.$emit('close');
    },
    fullname(user) {
      return `${ user.fname } ${ user.sname }`;
    },
  },
  mixins: [FiltersMixin],
  created() {
    this.options = {
      permission,
    };
    api.setBearer(localStorage.getItem('token'));
  },
  mounted() {
    api
        .get(`/api/users`)
        .then((response) => {
          this.users = response;
          response.map((el) => {
            this.getAccess(el.id);
          });
        })
        .catch((err) => {
          console.log(
              'The API is facing issues. Please try again later.n' + err,
          );
        });
  },
};
</script>

<style src="./style.scss" lang="scss">
</style>